.body {
  font-family: Roboto;
}

.carousel {
    color: white;
   
  }
  
  /* Optional styling for content within slides */
  .carousel .carousel-item h1 {
    text-align: center; /* Center align content within slides */
    padding-top: 5%;
    font-size: 50px;
  }

  .four {
    text-align: center; /* Center align content within slides */
    padding-top: 5%;
    font-size: 150px;
  }

  .carousel .carousel-item p {
    text-align: center; /* Center align content within slides */
    padding-top: 7%;
    padding-bottom: 7%;
  }

.Carousel.Caption{
  color: white;
}


.card-body {
  padding: 5%;
  align-self: center;

}

.card-title {
  text-align: center;
  color: #3b4753;
  font-weight: 1000;
  font-size: 40px;
}

.container {
  text-align: center;
  align-content: center;
  justify-content: center;
}

.row {
  color: #3b4753;
  text-align: center;
  align-content: center;
  justify-content: center;
  padding: 2%;
}

.row {
  color: #3b4753;
  text-align: center;
  align-content: center;
  justify-content: center;
  padding: 2%;
}

.col {
  padding: 2%;
}

.valueCard h3, .valueCard p {
  text-align: left;
}

.centerCon  {
  padding-top: 50%;
}