.container {
  position: relative;
  width: 100%
}

.img-fluid {
  width: 100%;
  height:auto;
}

.overlay {
  position: absolute;
  top: 200px;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}