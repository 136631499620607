.top {
    text-align: center;
  }

  ul {
    list-style-type: none;
    text-align: center;
  }

  .container container{
    position: fixed-bottom;
    width: 100%;
    background-color: blue;
  }


  .icons {
    color: brown;
  }

  .list-unstyled a {
    text-decoration: none; /* Removes underline */
    color: inherit; /* Inherits color from parent */
  }